import React from 'react';
import styled from '@emotion/styled';
import Image from 'components/Molecule/Image';
import { FluidObject } from 'gatsby-image';
import { css } from '@emotion/react';
import { H2 } from 'components/Atom/Typo';

const Component = styled.div<{
  isAbsolute?: boolean;
  isRight: boolean;
  isCenter?: boolean;
}>`
  position: relative;
  width: 100%;
  /* height: 100%; */
  padding-top: 20px;

  border: solid 1px ${(props) => props.theme.colors.grey200};
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  z-index: 200;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }

  .voda_fluid_img {
    object-position: top;
  }

  ${(props) => {
    if (props.isRight) {
      return css`
        border-top-left-radius: 16px;
        padding-left: 20px;
        .voda_fluid_img {
          border-top-left-radius: 16px;
        }
      `;
    }
    return css`
      border-top-right-radius: 16px;
      padding-right: 20px;
      .voda_fluid_img {
        border-top-right-radius: 16px;
      }
    `;
  }}

  ${(props) => {
    if (props.isCenter) {
      return css`
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        padding-left: 20px;
        padding-right: 20px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.isAbsolute) {
      return css`
        .voda_fluid_img {
          /* position: absolute; */
          width: 684px;
        }
      `;
    }
    return css``;
  }}
`;

const BlurWrapper = styled.div`
  position: absolute;
  padding: 20px;
  left: 0px;
  top: 0px;
  z-index: 200;

  width: 100%;
  height: 100%;

  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  /* background-color: #f9f9f9; */

  display: flex;
  align-items: center;
  justify-content: center;

  ${H2} {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

interface ReportIllustProps {
  img: FluidObject;
  isRight: boolean;
  isCenter?: boolean;
  className?: string;
  isOpen?: boolean;
  isAbsolute?: boolean;
}

const ReportIllust = ({
  img,
  isRight,
  isCenter,
  className,
  isOpen,
  isAbsolute,
}: ReportIllustProps) => {
  return (
    <Component
      isAbsolute={isAbsolute}
      isRight={isRight}
      isCenter={isCenter}
      className={className}
    >
      {isOpen === false ? (
        <BlurWrapper>
          <H2>COMING SOON</H2>
        </BlurWrapper>
      ) : null}
      <Image img={img} />
    </Component>
  );
};

export default ReportIllust;
