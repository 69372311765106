import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';

const Component = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

interface IconCircleProps {
  Icon: StyledComponent<
    any,
    any,
    {
      ref?: React.Ref<any> | undefined;
    }
  >;
  className?: string;
}

const IconCircle = ({ Icon, className }: IconCircleProps) => {
  return (
    <Component className={className}>
      <Icon />
    </Component>
  );
};

export default IconCircle;
